export function SuccessSmallIcon() {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='8' cy='8' r='8' fill='#AED60E' />
      <g clipPath='url(#clip0_6279_8334)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.6203 5.89212C11.7789 6.06663 11.7789 6.34958 11.6203 6.52409L7.4536 11.1074C7.29495 11.2819 7.03773 11.2819 6.87908 11.1074L4.79575 8.81576C4.6371 8.64124 4.6371 8.3583 4.79575 8.18378C4.9544 8.00927 5.21162 8.00927 5.37027 8.18378L7.16634 10.1595L11.0457 5.89212C11.2044 5.7176 11.4616 5.7176 11.6203 5.89212Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_6279_8334'>
          <rect
            width='10'
            height='11'
            fill='white'
            transform='translate(3 3)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
