export enum TransmissionEnum {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}

export enum FuelTypeEnum {
  BENZINE = 'BENZINE',
  DIESEL = 'DIESEL',
  METHANE = 'METHANE',
  PROPANE = 'PROPANE',
  ELECTRICITY = 'ELECTRICITY',
}

export enum CarTariffEnum {
  ECONOMY = 'ECONOMY',
  BUSINESS = 'BUSINESS',
}

export enum ScheduleTypeEnum {
  SEVEN_ZERO = 'SEVEN_ZERO',
  SIX_ONE = 'SIX_ONE',
}

export enum RentalStatusEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  APPROVED = 'APPROVED',
}

export enum AcceptanceDocumentTypeEnum {
  DriverPersonalData = 'DriverPersonalData',
  PublicOffer = 'PublicOffer',
}

export enum MoveFundsType {
  COMING = 'COMING',
  SPENDING = 'SPENDING',
}

export enum RentalContractStatusEnum {
  SUSPENDED = 'SUSPENDED',
}

export enum BannerPositionEnum {
  INFORMATION = 'information',
}

export enum SupportEnum {
  ADDRESS_AND_CONTACTS = 'address_and_contacts',
  CAR_NOT_START = 'car_not_start',
  BREAKDOWN_ON_ROAD = 'breakdown_on_road',
  DOCUMENTS = 'documents',
  TRAFFIC_POLICE_FINES = 'traffic_police_fines',
  EUROPEAN_PROTOCOL = 'european_protocol',
  ROAD_ACCIDENT = 'road_accident',
}

export enum ContactMethodEnum {
  phone = 'phone',
  telegram = 'telegram',
}
