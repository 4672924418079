import { createDomain, sample } from 'effector'
import { Driver } from '~/shared/api'
import { replaceFx } from '~/shared/lib/history'
import { loadingModel } from '~/shared/lib/loading'
import { snackbarEnqueued } from '~/shared/lib/notifications'

const domain = createDomain('features.taxiparks.listTaxiparks')

export const setMainTaxipark = domain.createEvent<UniqueId>()
export const setMainTaxiparkFx = domain.createEffect<UniqueId, void>({
  async handler(taxipark) {
    await Driver.setMainTaxipark(taxipark)
  },
})

sample({
  clock: setMainTaxipark,
  target: setMainTaxiparkFx,
})

sample({
  clock: setMainTaxipark,
  fn: () => ({
    message: 'Сохранение',
  }),
  target: loadingModel.present,
})

sample({
  clock: setMainTaxiparkFx.doneData,
  fn() {
    return '/'
  },
  target: replaceFx,
})

sample({
  clock: setMainTaxiparkFx.failData,
  fn() {
    return {
      message: 'Ошибка сохранения Таксопарка',
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: setMainTaxiparkFx.finally,
  target: loadingModel.dismiss,
})
