import { createDomain, createStore, sample } from 'effector'
import { CmsBlock, Contact } from '~/shared/api'
import { SupportEnum } from '~/shared/config/enums'

const domain = createDomain('entities.support')

export const openSupportDrawer = domain.createEvent()
export const closeSupportDrawer = domain.createEvent()

export const $isSupportDrawerOpen = domain
  .createStore<boolean>(false)
  .on(openSupportDrawer, () => true)
  .on(closeSupportDrawer, () => false)

export const getSupports = domain.createEvent()
export const getSupportsFx = domain.createEffect({
  async handler() {
    const response = await CmsBlock.limit(100)
      .orderBy('sort')
      .where('position', Object.values(SupportEnum).join(','))
      .get(1)

    return response.getData()
  },
})

export const $supports = createStore<CmsBlock[] | null>(null)
  .on(getSupportsFx.doneData, (_, support) => support)
  .on([getSupportsFx.failData], () => null)

sample({
  clock: $isSupportDrawerOpen,
  filter: Boolean,
  target: getSupports,
})

sample({
  clock: getSupports,
  target: getSupportsFx,
})

export const getContacts = domain.createEvent()
export const getContactsFx = domain.createEffect({
  async handler() {
    const response = await Contact.getAll()
    return response
  },
})

export const $contacts = createStore<Contact[] | null>(null)
  .on(getContactsFx.doneData, (_, contacts) => contacts)
  .on([getContactsFx.failData], () => null)

sample({
  clock: $isSupportDrawerOpen,
  filter: Boolean,
  target: getContacts,
})

sample({
  clock: getContacts,
  target: getContactsFx,
})
