import * as z from 'zod'
import { ApiModel } from './core'

const schema = z.object({
  method: z.string(),
  value: z.string(),
  description: z.string(),
})

export type ContactAttributes = z.infer<typeof schema>

export class Contact extends ApiModel<typeof schema, ContactAttributes> {
  static jsonApiType = 'contacts'

  static schema = schema

  static async getAll(): Promise<Contact[]> {
    const url = Contact.getJsonApiUrl()
    const response = await Contact.effectiveHttpClient.get(url)

    return (response?.getData()?.data || []).map(
      (contact: { attributes: ContactAttributes; id: UniqueId }) =>
        new Contact(contact.attributes, contact.id),
    )
  }
}
