import { Box, styled, Typography } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const Content = styled(Box)`
  color: ${myPalette.midnightBlue.main};

  h1 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }

  p,
  a {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
  }

  a {
    color: ${myPalette.blue.main};
    padding: 0;
    text-decoration: none;
    font-weight: 500;
    white-space: nowrap;
  }
`

export const Card = styled(Box)`
  height: 68px;
  background: ${myPalette.white.main};
  border-radius: 8px;
  box-shadow: 0 0 5px 0 rgba(0, 21, 70, 0.1);
  padding: 16px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

export const TitleWrapper = styled(Box)`
  display: flex;
  align-items: center;
`

export const Title = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  color: ${myPalette.midnightBlue.main};
  cursor: pointer;
`
