import {
  CarTariffEnum,
  FuelTypeEnum,
  TransmissionEnum,
  ScheduleTypeEnum,
  RentalStatusEnum,
  RentalContractStatusEnum,
} from '~/shared/config/enums'

export const AUTH_TOKEN_STORAGE_KEY = 'auth_token'
export const AUTH_PHONE_STORAGE_KEY = 'auth_phone'
export const SELECTED_PAY_CARD_BANK_CARD_ID = 'SELECTED_PAY_CARD_BANK_CARD_ID'
export const CURRENCY = '₽'

export type Option = { id: string; label: string }

export const carTariffEnumLabels: Record<CarTariffEnum, string> = {
  [CarTariffEnum.ECONOMY]: 'Эконом',
  [CarTariffEnum.BUSINESS]: 'Бизнес',
}

export const carTariffEnumLOptions: Option[] = Object.keys(CarTariffEnum).map(
  (value) => {
    const label = carTariffEnumLabels[value as keyof typeof CarTariffEnum]
    return {
      label,
      id: value,
    }
  },
)

export const transmissionEnumLabels: Record<TransmissionEnum, string> = {
  [TransmissionEnum.AUTO]: 'Автомат',
  [TransmissionEnum.MANUAL]: 'Механика',
}

export const fuelTypeEnumLabels: Record<FuelTypeEnum, string> = {
  [FuelTypeEnum.BENZINE]: 'Бензин',
  [FuelTypeEnum.DIESEL]: 'Дизель',
  [FuelTypeEnum.METHANE]: 'Метан',
  [FuelTypeEnum.ELECTRICITY]: 'Электро',
  [FuelTypeEnum.PROPANE]: 'Пропан',
}

export const scheduleTypeEnumLabels: Record<ScheduleTypeEnum, string> = {
  [ScheduleTypeEnum.SEVEN_ZERO]: '7/0',
  [ScheduleTypeEnum.SIX_ONE]: '6/1',
}
export const scheduleTypeEnumOptions: Option[] = Object.keys(
  ScheduleTypeEnum,
).map((value) => {
  const label = scheduleTypeEnumLabels[value as keyof typeof ScheduleTypeEnum]
  return {
    label: label,
    id: value,
  }
})

export const rentalStatusEnumLabels: Record<RentalStatusEnum, string> = {
  [RentalStatusEnum.IN_PROGRESS]: 'На рассмотрении',
  [RentalStatusEnum.APPROVED]: 'Одобрено',
}

export const rentalContractStatusEnumLabels: Record<
  RentalContractStatusEnum,
  string
> = {
  [RentalContractStatusEnum.SUSPENDED]: 'Приостановлен',
}
