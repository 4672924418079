import * as Sentry from '@sentry/react'
import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { authStore } from '~/shared/api'

export const SentryGate = createGate()
export const domain = createDomain('shared.lib.sentryModel')

export const setUser = domain.createEvent<string>()
export const setUserFx = domain.createEffect<string, void>({
  async handler(phone) {
    Sentry.setUser({ phone })
  },
})

sample({
  clock: setUser,
  target: setUserFx,
})

sample({
  clock: SentryGate.open,
  filter: () => Boolean(authStore.phone),
  fn() {
    return authStore.phone
  },
  target: setUserFx,
})

export const clearUser = domain.createEvent()
export const clearUserFx = domain.createEffect({
  async handler() {
    Sentry.setUser({ phone: '-' })
  },
})

sample({
  clock: clearUser,
  target: clearUserFx,
})
