import { IonModal as IonicIonModal } from '@ionic/react'
import MuiCloseIcon from '@mui/icons-material/Close'
import { Box, styled } from '@mui/material'
import { Img as DefaultImg } from '~/shared/ui/Img'
import { BODY_MAX_WIDTH, myPalette } from '~/shared/ui/theme'

export const CloseIcon = styled(MuiCloseIcon)`
  position: absolute;
  right: 14px;
  top: 14px;
  font-size: 44px;
  color: ${myPalette.dark.main};
  cursor: pointer;
  z-index: 6;
`

export const Img = styled(DefaultImg)`
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
  cursor: pointer;
  z-index: -1;
`

export const Wrapper = styled(Box)`
  position: relative;
  overflow-y: auto;
  overscroll-behavior: none;
  display: flex;
  flex-direction: column;
`

export const ContentWrapper = styled(Box)`
  padding: 16px 16px 32px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: ${myPalette.aliceBlue.main};
  margin-top: -26px;
  position: relative;
  z-index: 1;
  flex: 1;
`

export const Content = styled(Box)`
  color: ${myPalette.midnightBlue.main};

  h1 {
    font-size: 22px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 24px;
  }

  p {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
  }
`

export const IonModal = styled(IonicIonModal)`
  --border-radius: 20px !important;
  --max-width: ${BODY_MAX_WIDTH};

  &::part(content) {
    background: ${myPalette.aliceBlue.main};
  },
}
`
