import * as z from 'zod'
import { ApiModel } from './core'

const schema = z.object({
  position: z.string(),
  content: z.string(),
  image: z.object({
    uuid: z.string(),
    url: z.string(),
  }),
  previewContent: z.string(),
  previewImage: z.object({
    uuid: z.string(),
    url: z.string(),
  }),
})

export type CmsBlockAttributes = z.infer<typeof schema>

export class CmsBlock extends ApiModel<typeof schema, CmsBlockAttributes> {
  static jsonApiType = 'cms-blocks'

  static schema = schema
}
