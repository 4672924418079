import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useStore, useStoreMap } from 'effector-react'
import { supportModel } from '~/entities/Support'
import { Card, TitleWrapper, Title } from '~/entities/Support/ui/styled'
import { Skeletons } from '~/entities/Support/ui/TabsDrawer/Skeletons'
import { SupportEnum } from '~/shared/config/enums'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { PhoneIcon } from '~/shared/ui/icons'

type TabsDrawerProps = {
  isShow: boolean
  onClose: () => void
  onDriverSupportService: () => void
  onParkAddressAndContactsOpen: () => void
  onCarNotStartOpen: () => void
  onRoadAccidentOpen: () => void
  onBreakdownOnRoadOpen: () => void
  onDocumentsOpen: () => void
  onTrafficPoliceFinesDrawerOpen: () => void
}

export function TabsDrawer({
  isShow,
  onClose,
  onDriverSupportService,
  onParkAddressAndContactsOpen,
  onCarNotStartOpen,
  onRoadAccidentOpen,
  onBreakdownOnRoadOpen,
  onDocumentsOpen,
  onTrafficPoliceFinesDrawerOpen,
}: TabsDrawerProps) {
  const isSupportsPending = useStore(supportModel.getSupportsFx.pending)
  const isContactsPending = useStore(supportModel.getContactsFx.pending)

  const supports = useStoreMap(supportModel.$supports, (support) =>
    support?.map((support) => ({
      ...support.getAttributes(),
      id: support.getApiId() as UniqueId,
    })),
  )

  const isAddressAndContactsVisible = (supports || []).some(
    ({ position }) => position === SupportEnum.ADDRESS_AND_CONTACTS,
  )
  const isCarNotStartVisible = (supports || []).some(
    ({ position }) => position === SupportEnum.CAR_NOT_START,
  )
  const isBreakdownOnRoad = (supports || []).some(
    ({ position }) => position === SupportEnum.BREAKDOWN_ON_ROAD,
  )
  const isDocuments = (supports || []).some(
    ({ position }) => position === SupportEnum.DOCUMENTS,
  )
  const isTrafficPoliceFines = (supports || []).some(
    ({ position }) => position === SupportEnum.TRAFFIC_POLICE_FINES,
  )

  return (
    <DrawerComponent isShow={isShow} onCancel={onClose}>
      {(isSupportsPending || isContactsPending) && <Skeletons />}

      {!isSupportsPending && !isContactsPending && (
        <>
          <Card onClick={onDriverSupportService}>
            <TitleWrapper>
              <PhoneIcon />
              <Title sx={{ ml: '12px' }}>Служба поддержки водителей</Title>
            </TitleWrapper>
            <ChevronRightIcon />
          </Card>
          {isAddressAndContactsVisible && (
            <Card onClick={onParkAddressAndContactsOpen}>
              <TitleWrapper>
                <Title sx={{ ml: '12px' }}>Адрес и контакты парка</Title>
              </TitleWrapper>
              <ChevronRightIcon />
            </Card>
          )}
          {isCarNotStartVisible && (
            <Card onClick={onCarNotStartOpen}>
              <TitleWrapper>
                <Title sx={{ ml: '12px' }}>Автомобиль НЕ заводится</Title>
              </TitleWrapper>
              <ChevronRightIcon />
            </Card>
          )}
          <Card onClick={onRoadAccidentOpen}>
            <TitleWrapper>
              <Title sx={{ ml: '12px' }}>ДТП</Title>
            </TitleWrapper>
            <ChevronRightIcon />
          </Card>
          {isBreakdownOnRoad && (
            <Card onClick={onBreakdownOnRoadOpen}>
              <TitleWrapper>
                <Title sx={{ ml: '12px' }}>Поломка в дороге</Title>
              </TitleWrapper>
              <ChevronRightIcon />
            </Card>
          )}
          {isDocuments && (
            <Card onClick={onDocumentsOpen}>
              <TitleWrapper>
                <Title sx={{ ml: '12px' }}>Документы</Title>
              </TitleWrapper>
              <ChevronRightIcon />
            </Card>
          )}
          {isTrafficPoliceFines && (
            <Card onClick={onTrafficPoliceFinesDrawerOpen}>
              <TitleWrapper>
                <Title sx={{ ml: '12px' }}>Штрафы ГИБДД</Title>
              </TitleWrapper>
              <ChevronRightIcon />
            </Card>
          )}
        </>
      )}
    </DrawerComponent>
  )
}
