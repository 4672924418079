import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useStoreMap } from 'effector-react'
import { useState } from 'react'
import { supportModel } from '~/entities/Support'
import { Content } from '~/entities/Support/ui/styled'
import { Card, TitleWrapper, Title } from '~/entities/Support/ui/styled'
import { CmsBlockAttributes } from '~/shared/api'
import { SupportEnum } from '~/shared/config/enums'
import { DrawerComponent, useDrawer } from '~/shared/ui/Drawer'

type ParkAddressAndContactsDrawerProps = {
  isShow: boolean
  onClose: () => void
}

export function ParkAddressAndContactsDrawer({
  isShow,
  onClose,
}: ParkAddressAndContactsDrawerProps) {
  const [selectedSupport, setSelectedSupport] =
    useState<CmsBlockAttributes | null>()

  const { openDrawer, closeDrawer, isDrawerShow } = useDrawer()

  const supports = useStoreMap(supportModel.$supports, (support) =>
    (support || [])?.map((support) => ({
      ...support.getAttributes(),
      id: support.getApiId() as UniqueId,
    })),
  ).filter(({ position }) => position === SupportEnum.ADDRESS_AND_CONTACTS)

  const handleSelectSupportOpen = (support: CmsBlockAttributes) => {
    setSelectedSupport(support)
    openDrawer()
  }

  return (
    <>
      <DrawerComponent isShow={isShow} onCancel={onClose}>
        {supports.map((support) => (
          <Card
            key={support.id}
            onClick={() => handleSelectSupportOpen(support)}
          >
            <TitleWrapper>
              <Title sx={{ ml: '12px' }}>{support.previewContent}</Title>
            </TitleWrapper>
            <ChevronRightIcon />
          </Card>
        ))}
      </DrawerComponent>

      <DrawerComponent isShow={isDrawerShow} onCancel={closeDrawer}>
        <Content
          dangerouslySetInnerHTML={{
            __html: selectedSupport?.content || 'Контент не найден',
          }}
        />
      </DrawerComponent>
    </>
  )
}
