import React from 'react'
import { ObjectParam } from 'use-query-params'

export const identity = <ValueType>(value: ValueType): ValueType => value

type TimesIterateeFn<T> = (n: number) => T
export const times = <T = number>(
  n: number,
  iteratee: TimesIterateeFn<T> = (arg) => arg as T,
) => [...Array(n).keys()].map((n) => iteratee(n))

export const isString = (arg: unknown): arg is string => typeof arg === 'string'

export const isObject = (arg: unknown): arg is object =>
  typeof arg === 'object' && arg !== null && !Array.isArray(arg)

export const isEmptyObject = (arg: unknown) =>
  isObject(arg) &&
  Object.entries(arg).filter(([, v]) => typeof v !== 'undefined').length === 0

export const getObject = <T>(arg: T) => (isObject(arg) ? arg : undefined)

export const sanitizePhoneNumber = (phone: string) =>
  (phone ?? '').replace(/[(),\-_\s+]/g, '')

export const cutStringBefore = (str: string, char: string) =>
  str.replace(new RegExp(`.*?${char}(.*)`), '$1')

export const sanitizePriceNumber = (price: string | number) =>
  parseFloat(String(price).replace(/\s+/g, '').replace(/,/g, '.'))

export const priceFormat = (price: number | string) => {
  return Number(price).toLocaleString('ru-RU', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
}

export const mapSearchParamsToFilters = (searchParams: URLSearchParams) => {
  return Object.fromEntries(
    [...searchParams]
      .map(([key, value]) => [key, ObjectParam.decode(value)?.id || value])
      .filter(([, value]) => Boolean(value)),
  )
}

export const cutStringAfter = (str: string, char: string) => str.split(char)[0]

export const decodeSelect = (value: string | undefined) => {
  if (!isString(value)) return undefined
  const obj = {
    label: cutStringBefore(cutStringAfter(value, '_id-'), 'label-'),
    id: cutStringBefore(value, '_id-'),
  }
  return obj.id ? obj : undefined
}

export const decodeAutocomplete = (value: string | undefined) => {
  if (!isString(value)) return undefined
  const obj = {
    label: cutStringBefore(value, '_label-'),
    id: cutStringBefore(cutStringAfter(value, '_label-'), 'id-'),
  }
  return obj.id ? obj : undefined
}

export const renderHTML = (rawHTML: string) =>
  React.createElement('div', { dangerouslySetInnerHTML: { __html: rawHTML } })

export const formatPhoneNumber = (phoneNumber: string | number) => {
  const cleaned = String(phoneNumber).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/)

  if (match) return `+7 (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`

  return phoneNumber
}
