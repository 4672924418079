import { useStoreMap } from 'effector-react'
import { driverModel } from '~/entities/Driver'
import { formatDateForUI } from '~/shared/lib/date'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { Label, Field } from './styled'

type PassportDetailsDrawerProps = {
  isShow: boolean
  onClose: () => void
}

export function PassportDetailsDrawer({
  isShow,
  onClose,
}: PassportDetailsDrawerProps) {
  const fullName = useStoreMap(driverModel.$driver, (driver) =>
    driver?.getFullName(),
  )
  const passportNumber = useStoreMap(driverModel.$driver, (driver) =>
    driver?.getPassportNumber(),
  )
  const birthDate = useStoreMap(driverModel.$driver, (driver) =>
    driver?.getBirthDate(),
  )
  const passportIssueDate = useStoreMap(driverModel.$driver, (driver) =>
    driver?.getPassportIssueDate(),
  )
  const passportIssued = useStoreMap(driverModel.$driver, (driver) =>
    driver?.getPassportIssued(),
  )
  const citizenship = useStoreMap(driverModel.$driver, (driver) =>
    driver?.getCitizenship(),
  )

  return (
    <DrawerComponent isShow={isShow} onCancel={onClose}>
      <Label>ФИО</Label>
      <Field data-testid='passport-details-full-name'>{fullName}</Field>

      <Label>Серия и номер</Label>
      <Field data-testid='passport-details-passport-number'>
        {passportNumber}
      </Field>

      <Label>Дата рождения</Label>
      <Field data-testid='passport-details-birth-date'>
        {birthDate ? formatDateForUI(birthDate) : '-'}
      </Field>

      <Label>Дата выдачи</Label>
      <Field data-testid='passport-details-passport-issue-date'>
        {passportIssueDate ? formatDateForUI(passportIssueDate) : '-'}
      </Field>

      <Label>Кем выдан</Label>
      <Field data-testid='passport-details-passport-issued'>
        {passportIssued}
      </Field>

      <Label>Гражданство</Label>
      <Field data-testid='passport-details-citizenship'>{citizenship}</Field>
    </DrawerComponent>
  )
}
