import React, { useMemo, useState } from 'react'

import {
  TIMER_REPEAT_PROPOSAL,
  TIMER_REPEAT_PROPOSAL_TIME,
} from '~/widgets/Proposal/constants'
import { VerificationFieldElement } from '~/shared/ui/form'
import { Modal } from '~/shared/ui/Modal'
import { RepeatCode } from '~/shared/ui/RepeatCode'
import { Description, Title } from './styled'

type CodeConfirmationModalProps = {
  isShow: boolean
  onOk: (code: string) => Promise<void>
  onCodeRepeat: () => void
}

export function CodeConfirmationModal({
  isShow,
  onOk,
  onCodeRepeat,
}: CodeConfirmationModalProps) {
  const [code, setCode] = useState<string>()

  const handleCodeChange = (code: string) => setCode(code)

  const okButtonProps = useMemo(
    () => ({
      disabled: code?.length !== 4,
    }),
    [code?.length],
  )

  const handleOnOk = async () => {
    await onOk(code as string)
  }

  return (
    <Modal
      isShow={isShow}
      ok='Подписать'
      onOk={handleOnOk}
      okButtonProps={okButtonProps}
    >
      <Title>Последний шаг!</Title>
      <Description>
        Введите код, который придет на ваш номер, чтобы получить полный доступ к
        приложению
      </Description>

      <VerificationFieldElement onChange={handleCodeChange} />
      <br />

      <RepeatCode
        onCodeRepeat={onCodeRepeat}
        time={TIMER_REPEAT_PROPOSAL_TIME}
        storageKey={TIMER_REPEAT_PROPOSAL}
      />
    </Modal>
  )
}
