import * as z from 'zod'

import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributeSchema = z.object({
  name: z.string(),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)

export type TaxiparkAttributes = z.infer<typeof attributeSchema>

export class Taxipark extends ApiModel<typeof schema, TaxiparkAttributes> {
  static jsonApiType = 'taxiparks'

  static schema = schema

  getName(): string {
    return this.getAttribute('name')
  }
}
