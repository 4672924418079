import { Box, styled } from '@mui/material'

export const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
`
