import { Box } from '@mui/material'
import { BalanceNav } from '~/widgets/blocks/BalanceNav'
import { MainCar } from '~/features/MainCar'
import { ListStory } from '~/features/Story'
import { CurrentBalance } from '~/entities/MainBalance'
import { PageContainer } from '~/shared/ui/PageContainer'

function HomePage() {
  return (
    <PageContainer
      canMenu
      title='Апарк. Водитель'
      topContent={<MainCar />}
      extraRight={<CurrentBalance />}
    >
      <Box sx={{ mb: '24px' }}>
        <BalanceNav />
      </Box>

      <Box sx={{ mb: '24px' }}>
        <ListStory />
      </Box>
    </PageContainer>
  )
}

export default HomePage
