import { useStoreMap } from 'effector-react'
import { driverModel } from '~/entities/Driver'
import { formatDateForUI } from '~/shared/lib/date'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { Label, Field } from './styled'

type DriverLicenseDrawerProps = {
  isShow: boolean
  onClose: () => void
}

export function DriverLicenseDrawer({
  isShow,
  onClose,
}: DriverLicenseDrawerProps) {
  const fullName = useStoreMap(driverModel.$driver, (driver) =>
    driver?.getFullName(),
  )
  const drivingLicenseNumber = useStoreMap(driverModel.$driver, (driver) =>
    driver?.getDrivingLicenseNumber(),
  )
  const birthDate = useStoreMap(driverModel.$driver, (driver) =>
    driver?.getBirthDate(),
  )
  const drivingLicenseIssueDate = useStoreMap(driverModel.$driver, (driver) =>
    driver?.getDrivingLicenseIssueDate(),
  )
  const drivingLicenseExpirationDate = useStoreMap(
    driverModel.$driver,
    (driver) => driver?.getDrivingLicenseExpirationDate(),
  )
  const drivingLicenseIssuingCountry = useStoreMap(
    driverModel.$driver,
    (driver) => driver?.getDrivingLicenseIssuingCountry(),
  )

  return (
    <DrawerComponent isShow={isShow} onCancel={onClose}>
      <Label>ФИО</Label>
      <Field data-testid='license-full-name'>{fullName}</Field>

      <Label>Серия и номер</Label>
      <Field data-testid='license-driving-license-number'>
        {drivingLicenseNumber}
      </Field>

      <Label>Дата рождения</Label>
      <Field data-testid='license-birth-date'>
        {birthDate ? formatDateForUI(birthDate) : '-'}
      </Field>

      <Label>Дата выдачи</Label>
      <Field data-testid='license-driving-license-issue-date'>
        {drivingLicenseIssueDate
          ? formatDateForUI(drivingLicenseIssueDate)
          : '-'}
      </Field>

      <Label>Дата окончания</Label>
      <Field data-testid='license-driving-license-expiration-date'>
        {drivingLicenseExpirationDate
          ? formatDateForUI(drivingLicenseExpirationDate)
          : '-'}
      </Field>

      <Label>Страна выдачи</Label>
      <Field data-testid='license-driving-license-issuing-country'>
        {drivingLicenseIssuingCountry}
      </Field>
    </DrawerComponent>
  )
}
