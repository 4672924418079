import { UseIonLoadingResult } from '@ionic/react'
import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'

type Present = UseIonLoadingResult[0]
type Dismiss = UseIonLoadingResult[1]

export const LoadingGate = createGate<UseIonLoadingResult>()

const domain = createDomain('shared.lib.loading')

const $present = domain
  .createStore<Present | null>(null)
  .on(LoadingGate.open, (_, gateFn) => {
    return gateFn?.[0] || null
  })

export const present = domain.createEvent<{ message: string }>()

const callPresentFx = domain.createEffect<
  {
    present: Present | null
    payload: { message: string }
  },
  void
>({
  async handler({ present, payload }) {
    present?.(payload)
  },
})

sample({
  clock: present,
  source: $present,
  fn: (present, payload) => ({
    present,
    payload,
  }),
  target: callPresentFx,
})

const $dismiss = domain
  .createStore<Dismiss | null>(null)
  .on(LoadingGate.open, (_, gateFn) => {
    return gateFn?.[1] || null
  })

export const dismiss = domain.createEvent<Dismiss>()
const callDismissFx = domain.createEffect((dismiss: Dismiss | null) => {
  if (dismiss) {
    return dismiss()
  }
})

sample({
  clock: dismiss,
  source: $dismiss,
  target: callDismissFx,
})
