import { Skeleton } from './styled'

export function Skeletons() {
  return (
    <>
      <Skeleton variant='rounded' />
      <Skeleton variant='rounded' />
      <Skeleton variant='rounded' />
      <Skeleton variant='rounded' />
      <Skeleton variant='rounded' />
      <Skeleton variant='rounded' />
      <Skeleton variant='rounded' />
    </>
  )
}
