import { Box, Typography } from '@mui/material'
import { useGate, useStore, useStoreMap } from 'effector-react'
import { useState } from 'react'
import { PreviewList, StoryModal } from '~/entities/Story'
import { $storyList, Gate, getStoryListFx } from './model'

export function ListStory() {
  useGate(Gate)
  const storyListPending = useStore(getStoryListFx.pending)

  const storyList = useStoreMap($storyList, (storyList) =>
    storyList?.map((story) => ({
      ...story.getAttributes(),
      id: story.getApiId() as UniqueId,
    })),
  )

  const [selectedStoryId, setSelectedStoryId] = useState<string>()

  const handleStoryOpen = (storyId: UniqueId) => {
    setSelectedStoryId(storyId)
  }
  const handleStoryClose = () => {
    setSelectedStoryId(undefined)
  }

  if (!storyListPending && !storyList?.length) return null

  return (
    <Box data-testid='story-list-block'>
      <Typography
        fontWeight={700}
        fontSize={18}
        color='midnightBlue.main'
        mb='24px'
      >
        Информация
      </Typography>
      <PreviewList
        onClick={handleStoryOpen}
        storyList={storyList}
        isLoading={storyListPending}
      />
      <StoryModal
        storyId={selectedStoryId}
        storyList={storyList}
        onClose={handleStoryClose}
      />
    </Box>
  )
}
