import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useStoreMap } from 'effector-react'
import { useState } from 'react'
import { supportModel } from '~/entities/Support'
import { Content } from '~/entities/Support/ui/styled'
import { Card, TitleWrapper, Title } from '~/entities/Support/ui/styled'
import { CmsBlockAttributes } from '~/shared/api'
import { SupportEnum } from '~/shared/config/enums'
import { DrawerComponent, useDrawer } from '~/shared/ui/Drawer'

type RoadAccidentDrawerProps = {
  isShow: boolean
  onClose: () => void
}

export function RoadAccidentDrawer({
  isShow,
  onClose,
}: RoadAccidentDrawerProps) {
  const {
    openDrawer: roadAccidentOpen,
    closeDrawer: roadAccidentClose,
    isDrawerShow: isRoadAccidentShow,
  } = useDrawer()

  const { openDrawer, closeDrawer, isDrawerShow } = useDrawer()

  const [selectedSupport, setSelectedSupport] =
    useState<CmsBlockAttributes | null>()

  const supports = useStoreMap(supportModel.$supports, (support) =>
    (support || [])?.map((support) => ({
      ...support.getAttributes(),
      id: support.getApiId() as UniqueId,
    })),
  )

  const europeanProtocolSupport = (supports || []).find(
    ({ position }) => position === SupportEnum.EUROPEAN_PROTOCOL,
  )

  const roadAccidentSupports = (supports || []).filter(
    ({ position }) => position === SupportEnum.ROAD_ACCIDENT,
  )

  const handleSelectSupportOpen = (support: CmsBlockAttributes) => {
    setSelectedSupport(support)
    openDrawer()
  }

  return (
    <>
      <DrawerComponent isShow={isShow} onCancel={onClose}>
        {!roadAccidentSupports?.length && !europeanProtocolSupport && (
          <>Контент не найден</>
        )}

        {!!roadAccidentSupports?.length && (
          <Card onClick={roadAccidentOpen}>
            <TitleWrapper>
              <Title sx={{ ml: '12px' }}>
                Вы стали участником ДТП, что делать?
              </Title>
            </TitleWrapper>
            <ChevronRightIcon />
          </Card>
        )}
        {!!europeanProtocolSupport && (
          <Card
            onClick={() => handleSelectSupportOpen(europeanProtocolSupport)}
          >
            <TitleWrapper>
              <Title sx={{ ml: '12px' }}>
                {europeanProtocolSupport.previewContent}
              </Title>
            </TitleWrapper>
            <ChevronRightIcon />
          </Card>
        )}
      </DrawerComponent>

      <DrawerComponent isShow={isRoadAccidentShow} onCancel={roadAccidentClose}>
        {roadAccidentSupports.map((support) => (
          <Card
            key={support.id}
            onClick={() => handleSelectSupportOpen(support)}
          >
            <TitleWrapper>
              <Title sx={{ ml: '12px' }}>{support.previewContent}</Title>
            </TitleWrapper>
            <ChevronRightIcon />
          </Card>
        ))}
      </DrawerComponent>

      <DrawerComponent isShow={isDrawerShow} onCancel={closeDrawer}>
        <Content
          dangerouslySetInnerHTML={{
            __html: selectedSupport?.content || 'Контент не найден',
          }}
        />
      </DrawerComponent>
    </>
  )
}
