import { Skeleton, Typography } from '@mui/material'
import { useStore } from 'effector-react'
import { $commission } from '~/entities/Balance/model'
import { CURRENCY } from '~/shared/config/constants'
import { myPalette } from '~/shared/ui/theme'
import { Wrapper } from './styled'

type CommissionProps = {
  isLoading: boolean
}

export function Commission({ isLoading }: CommissionProps) {
  const commission = useStore($commission)

  if (!commission?.commissionAmount && !isLoading) {
    return (
      <Typography
        lineHeight='12px'
        fontSize={12}
        mb='11px'
        color={myPalette.mutedText.main}
      >
        Размер комиссии будет уточнен после выбора <br /> карты и ввода суммы
      </Typography>
    )
  }
  return (
    <Wrapper>
      <Typography>Комиссия</Typography> —{' '}
      {isLoading ? (
        <Skeleton variant='rounded' height={24} width={70} sx={{ ml: '4px' }} />
      ) : (
        <span>
          {commission?.commissionAmount || 0} {CURRENCY}
        </span>
      )}
    </Wrapper>
  )
}
