import { styled, Stack } from '@mui/material'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const WhiteBlock = styled(Stack)`
  background-color: ${myPalette.white.main};
  border-radius: 12px;
  box-shadow: 0 0 5px 0 #0015461a;
  padding: 16px 12px;
  width: 100%;
`
