import { Skeleton } from '@mui/material'
import { useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { driverModel } from '~/entities/Driver'
import { Title, SubTitle, Wrapper, Button } from './styled'

export function MainTaxipark() {
  const driverPending = useStore(driverModel.getDriverFx.pending)

  const taxiparksCount = useStoreMap(
    driverModel.$driver,
    (driver) => driver?.getTaxiparks()?.length || 0,
  )

  const mainTaxiparkName = useStoreMap(driverModel.$driver, (driver) =>
    (driver?.getTaxiparks() || [])
      .find((taxipark) => taxipark.getApiId() === driver?.getMainTaxiparkId())
      ?.getName(),
  )

  const isTaxiparkChange = mainTaxiparkName && taxiparksCount > 1

  const taxiparkTitle = useMemo(() => {
    if (driverPending) {
      return <Skeleton variant='rounded' height={24} width='100%' />
    }

    return mainTaxiparkName
  }, [driverPending, mainTaxiparkName])

  return (
    <Wrapper>
      <SubTitle>Активный таксопарк</SubTitle>
      <Title>{taxiparkTitle}</Title>
      {isTaxiparkChange && (
        <Button component={Link} to='/taxiparks' variant='lightSteelBlueBorder'>
          Изменить
        </Button>
      )}
    </Wrapper>
  )
}
