import { createDomain, createStore, sample } from 'effector'
import { createGate } from 'effector-react'
import { CmsBlock } from '~/shared/api'

import { BannerPositionEnum } from '~/shared/config/enums'
import { logger } from '~/shared/lib/logger'
import { snackbarEnqueued } from '~/shared/lib/notifications'

export const Gate = createGate()

const domain = createDomain('features.story.storyList')

export const getStoryList = domain.createEvent()
export const getStoryListFx = domain.createEffect({
  async handler() {
    const response = await CmsBlock.limit(10)
      .orderBy('sort')
      .where('position', BannerPositionEnum.INFORMATION)
      .get(1)

    return response.getData()
  },
})

export const $storyList = createStore<CmsBlock[] | null>(null)
  .on(getStoryListFx.doneData, (_, storyList) => storyList)
  .on([getStoryListFx.failData], () => null)

sample({
  clock: Gate.open,
  target: getStoryList,
})

sample({
  clock: getStoryList,
  target: getStoryListFx,
})

sample({
  clock: getStoryListFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка получения банеров информации',
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})
