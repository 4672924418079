import { ImageListItem } from '@mui/material'
import { Skeleton } from './styled'

export function SkeletonsList() {
  return (
    <>
      <ImageListItem sx={{ aspectRatio: '1/1' }}>
        <Skeleton variant='rounded' />
      </ImageListItem>
      <ImageListItem sx={{ aspectRatio: '1/1' }}>
        <Skeleton variant='rounded' />
      </ImageListItem>
    </>
  )
}
