import { createDomain } from 'effector'

const domain = createDomain('widgets.profile')

export const openProfileDrawer = domain.createEvent()
export const closeProfileDrawer = domain.createEvent()

export const $isProfileDrawerOpen = domain
  .createStore<boolean>(false)
  .on(openProfileDrawer, () => true)
  .on(closeProfileDrawer, () => false)
