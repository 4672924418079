type IconProps = {
  color?: string
}

export function LogoIcon({ color = '#382AFF' }: IconProps) {
  return (
    <svg
      width='100%'
      height='auto'
      viewBox='0 0 496 176'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M410.816 143.044V54.8852H434.292V88.6768H447.707L469.338 54.8852H495.832L468.164 96.3719L496 143.044H469.003L449.048 109.587H434.292V143.044H410.816Z'
        fill={color}
      />
      <path
        d='M305.156 176V54.8856H327.123L328.297 66.0937C331.874 61.4098 336.122 58.1756 341.041 56.3912C345.959 54.4953 351.157 53.5474 356.635 53.5474C365.019 53.5474 372.006 55.5548 377.595 59.5696C383.185 63.5845 387.433 69.0491 390.339 75.9636C393.246 82.7665 394.699 90.4616 394.699 99.0489C394.699 107.636 393.246 115.387 390.339 122.302C387.433 129.104 383.017 134.458 377.092 138.361C371.168 142.264 363.678 144.216 354.623 144.216C350.487 144.216 346.742 143.77 343.388 142.878C340.146 141.985 337.296 140.759 334.836 139.197C332.489 137.636 330.421 136.019 328.632 134.346V176H305.156ZM350.766 123.138C356.02 123.138 360.156 121.967 363.175 119.625C366.193 117.283 368.317 114.328 369.547 110.759C370.776 107.079 371.391 103.287 371.391 99.3835C371.391 95.1456 370.72 91.1307 369.379 87.3389C368.038 83.5471 365.802 80.536 362.672 78.3055C359.653 75.9636 355.517 74.7926 350.263 74.7926C345.568 74.7926 341.599 75.9636 338.358 78.3055C335.116 80.536 332.656 83.4914 330.979 87.1717C329.303 90.8519 328.464 94.811 328.464 99.0489C328.464 103.287 329.247 107.246 330.812 110.926C332.489 114.606 334.948 117.562 338.19 119.792C341.544 122.023 345.736 123.138 350.766 123.138Z'
        fill={color}
      />
      <path
        d='M107.919 143.043V75.4962H188.072V143.043H164.764V96.4068H131.395V143.043H107.919Z'
        fill={color}
      />
      <path
        d='M235.707 144.216C231.906 144.216 228.217 143.714 224.64 142.71C221.062 141.595 217.821 139.922 214.914 137.692C212.119 135.461 209.883 132.673 208.207 129.327C206.53 125.982 205.691 122.078 205.691 117.618C205.691 111.595 206.921 106.8 209.38 103.231C211.84 99.5507 215.138 96.7627 219.274 94.8668C223.41 92.9709 228.161 91.6884 233.527 91.0192C239.005 90.3501 244.65 90.0155 250.463 90.0155H263.207C263.207 86.7813 262.592 83.9375 261.362 81.484C260.244 78.9189 258.512 76.9673 256.164 75.629C253.817 74.1792 250.798 73.4543 247.109 73.4543C244.762 73.4543 242.47 73.7889 240.234 74.458C238.11 75.0156 236.322 75.852 234.868 76.9673C233.415 78.0825 232.465 79.5323 232.018 81.3167H208.207C208.877 76.4097 210.442 72.2275 212.902 68.7703C215.361 65.2016 218.435 62.3019 222.124 60.0715C225.813 57.841 229.838 56.2239 234.198 55.2202C238.669 54.105 243.252 53.5474 247.948 53.5474C256.555 53.5474 263.598 55.1644 269.076 58.3986C274.665 61.6328 278.801 66.3725 281.484 72.6179C284.279 78.8632 285.676 86.5025 285.676 95.5359V143.045H264.548L264.213 132.673C261.642 135.684 258.791 138.082 255.661 139.866C252.643 141.539 249.457 142.655 246.103 143.212C242.749 143.881 239.284 144.216 235.707 144.216ZM242.079 125.647C246.103 125.647 249.736 124.867 252.978 123.305C256.22 121.632 258.735 119.458 260.524 116.781C262.424 113.993 263.374 110.926 263.374 107.58V105.74H247.277C244.818 105.74 242.47 105.852 240.234 106.075C238.11 106.298 236.154 106.744 234.365 107.413C232.688 107.971 231.347 108.919 230.341 110.257C229.447 111.484 228.999 113.157 228.999 115.276C228.999 117.618 229.558 119.569 230.676 121.131C231.906 122.692 233.527 123.863 235.539 124.644C237.551 125.313 239.731 125.647 242.079 125.647Z'
        fill={color}
      />
      <path
        d='M38.4694 144.216C34.6686 144.216 30.9796 143.714 27.4023 142.71C23.8251 141.595 20.5832 139.922 17.6767 137.692C14.882 135.461 12.6462 132.673 10.9694 129.327C9.29252 125.982 8.4541 122.078 8.4541 117.618C8.4541 111.595 9.68378 106.8 12.1431 103.231C14.6025 99.5507 17.9003 96.7627 22.0365 94.8668C26.1726 92.9709 30.9237 91.6884 36.2895 91.0192C41.7672 90.3501 47.4125 90.0155 53.2256 90.0155H65.9695C65.9695 86.7813 65.3547 83.9375 64.125 81.484C63.0071 78.9189 61.2744 76.9673 58.9268 75.629C56.5792 74.1792 53.5609 73.4543 49.8719 73.4543C47.5243 73.4543 45.2327 73.7889 42.9969 74.458C40.8729 75.0156 39.0843 75.852 37.631 76.9673C36.1778 78.0825 35.2276 79.5323 34.7804 81.3167H10.9694C11.6401 76.4097 13.2051 72.2275 15.6645 68.7703C18.1238 65.2016 21.198 62.3019 24.8871 60.0715C28.5761 57.841 32.6005 56.2239 36.9603 55.2202C41.4318 54.105 46.0152 53.5474 50.7103 53.5474C59.3181 53.5474 66.3608 55.1644 71.8384 58.3986C77.4279 61.6328 81.5641 66.3725 84.247 72.6179C87.0417 78.8632 88.4391 86.5025 88.4391 95.5359V143.045H67.311L66.9756 132.673C64.4045 135.684 61.5538 138.082 58.4237 139.866C55.4054 141.539 52.2195 142.655 48.8658 143.212C45.5121 143.881 42.0467 144.216 38.4694 144.216ZM44.8414 125.647C48.8658 125.647 52.4989 124.867 55.7408 123.305C58.9827 121.632 61.4979 119.458 63.2866 116.781C65.187 113.993 66.1372 110.926 66.1372 107.58V105.74H50.0396C47.5802 105.74 45.2327 105.852 42.9969 106.075C40.8729 106.298 38.9166 106.744 37.128 107.413C35.4511 107.971 34.1097 108.919 33.1036 110.257C32.2092 111.484 31.7621 113.157 31.7621 115.276C31.7621 117.618 32.321 119.569 33.4389 121.131C34.6686 122.692 36.2895 123.863 38.3017 124.644C40.3139 125.313 42.4938 125.647 44.8414 125.647Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M281.464 33.5252C245.879 13.863 213.581 25.8469 196.09 44.8831L192.6 48.6804H102.043L98.5599 44.9382C70.6692 14.9708 31.1099 22.1859 14.3052 35.0702L0 16.5007C23.3821 -1.42654 74.4301 -11.1382 112.092 25.2605H182.495C207 1.66158 248.844 -11.2706 292.838 13.0377L281.464 33.5252Z'
        fill={color}
      />
    </svg>
  )
}
