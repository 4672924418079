import { useStore, useStoreMap } from 'effector-react'
import { useEffect } from 'react'
import {
  TIMER_REPEAT_PROPOSAL,
  TIMER_REPEAT_PROPOSAL_TIME,
} from '~/widgets/Proposal/constants'
import { driverModel } from '~/entities/Driver'
import { AcceptFetchValues } from '~/shared/api'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { formatPhoneNumber } from '~/shared/lib/utils'
import { useModal } from '~/shared/ui/Modal'
import { useStorageRepeatCode } from '~/shared/ui/RepeatCode'
import {
  $driverPersonalDataAcceptance,
  $publicOfferAcceptance,
  $acceptanceId,
  acceptFx,
  confirmFx,
  driverPersonalDataAcceptanceDelete,
  publicOfferAcceptanceDelete,
} from './model'
import { CodeConfirmationModal } from './ui/CodeConfirmationModal'
import { DriverPersonalDataModal } from './ui/DriverPersonalDataModal'
import { PublicOfferModal } from './ui/PublicOfferModal'

export function Proposal() {
  const driverPersonalDataAcceptance = useStore($driverPersonalDataAcceptance)
  const publicOfferAcceptance = useStore($publicOfferAcceptance)
  const acceptanceId = useStore($acceptanceId)

  const driverPhone = useStoreMap(driverModel.$driver, (profile) =>
    profile?.getPhone(),
  )

  const {
    isModalShow: isDriverPersonalDataModalShow,
    openModal: driverPersonalDataModalOpen,
    closeModal: driverPersonalDataModalClose,
  } = useModal()
  const {
    isModalShow: isPublicOfferModalShow,
    openModal: publicOfferModalOpen,
    closeModal: publicOfferModalClose,
  } = useModal()
  const {
    isModalShow: isCodeConfirmationModalShow,
    openModal: codeConfirmationModalOpen,
    closeModal: codeConfirmationModalClose,
  } = useModal()

  const { runTimer } = useStorageRepeatCode(TIMER_REPEAT_PROPOSAL)

  const handleDriverPersonalDataModalSuccess = async () => {
    await acceptFx({
      documentType: driverPersonalDataAcceptance?.documentType,
      documentId: driverPersonalDataAcceptance?.documentId,
    } as AcceptFetchValues).then(() => {
      driverPersonalDataModalClose()
      driverPersonalDataAcceptanceDelete()
      publicOfferAcceptance && publicOfferModalOpen()
    })
  }

  const handleCodeRequest = async () => {
    await acceptFx({
      documentType: publicOfferAcceptance?.documentType,
      documentId: publicOfferAcceptance?.documentId,
    } as AcceptFetchValues)
  }

  const handlePublicOfferModalSuccess = async () => {
    await handleCodeRequest()
    snackbarEnqueued({
      message: `Код подтверждения входа отправлен на номер ${formatPhoneNumber(
        driverPhone,
      )}`,
      variant: 'success' as const,
    })
    runTimer(TIMER_REPEAT_PROPOSAL_TIME)

    publicOfferModalClose()
    codeConfirmationModalOpen()
  }

  const handleCodeConfirmationModalSuccess = async (code: string) => {
    if (!acceptanceId) {
      snackbarEnqueued({
        message: 'Ошибка получения acceptanceId',
        variant: 'danger',
      })
      return
    }

    await confirmFx({
      acceptanceId: acceptanceId,
      confirmCode: code,
      deviceInfo: navigator.userAgent,
    }).then(() => {
      codeConfirmationModalClose()
      publicOfferAcceptanceDelete()
      snackbarEnqueued({
        message: 'Оферта принята! Спасибо за согласие!',
        variant: 'success',
      })
    })
  }

  useEffect(() => {
    if (driverPersonalDataAcceptance) {
      driverPersonalDataModalOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverPersonalDataAcceptance])

  useEffect(() => {
    if (publicOfferAcceptance && !driverPersonalDataAcceptance) {
      publicOfferModalOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverPersonalDataAcceptance, publicOfferAcceptance])

  return (
    <>
      <DriverPersonalDataModal
        isShow={isDriverPersonalDataModalShow}
        onOk={handleDriverPersonalDataModalSuccess}
      />
      <PublicOfferModal
        isShow={isPublicOfferModalShow}
        onOk={handlePublicOfferModalSuccess}
        onClose={publicOfferModalClose}
      />
      <CodeConfirmationModal
        onCodeRepeat={handleCodeRequest}
        isShow={isCodeConfirmationModalShow}
        onOk={handleCodeConfirmationModalSuccess}
      />
    </>
  )
}
