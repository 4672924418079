import { CircularProgress } from '@mui/material'
import { ImgHTMLAttributes, useCallback, useEffect, useState } from 'react'
import { Media } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { Wrapper } from './styled'

type ImgProps = {
  url: string
} & Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'>

export function Img({ url, ...props }: ImgProps) {
  const [isLoading, setIsLoading] = useState<boolean>()
  const [imageUrl, setImageUrl] = useState<string>()

  const handleMediaGet = useCallback(async () => {
    try {
      setIsLoading(true)
      const blob = await Media.getMedia(url)

      setImageUrl(URL.createObjectURL(blob))
    } catch (e) {
      logger.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [url])

  useEffect(() => {
    handleMediaGet()
    // eslint-disable-next-line
  }, [url])

  if (isLoading) {
    return (
      <Wrapper>
        <CircularProgress
          color='limeGreen'
          sx={{ display: 'block', width: '24px', height: '24px' }}
        />
      </Wrapper>
    )
  }

  return <img src={imageUrl} alt='Изоброжение' {...props} />
}
