import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { Penalty } from '~/shared/api'

const domain = createDomain('penalty-drawer')
export const Gate = createGate<Penalty>()

export const fetchImageFx = domain.createEffect<Penalty, Blob[]>({
  handler: (penalty) => {
    const files = penalty.getFiles() || []
    const promises = files.map(({ uuid }) =>
      Penalty.media(penalty.getApiId() as UniqueId, uuid),
    )
    return Promise.all(promises)
  },
})

export const $photos = domain
  .createStore<{ id: UniqueId; src: string }[]>([])
  .on(fetchImageFx.doneData, (_, blobs) =>
    blobs.map((blob) => {
      const src = URL.createObjectURL(blob)
      return {
        id: src,
        src,
      }
    }),
  )
  .on(Gate.close, () => [])

sample({
  clock: Gate.open,
  filter: (penalty) => Boolean(penalty && penalty.getFiles()?.length),
  target: fetchImageFx,
})
