import { createDomain, createStore, sample } from 'effector'
import { createGate } from 'effector-react'
import { Driver } from '~/shared/api'

import { logger } from '~/shared/lib/logger'
import { snackbarEnqueued } from '~/shared/lib/notifications'

export const Gate = createGate()

const domain = createDomain('entities.driver')

export const getDriver = domain.createEvent()
export const getDriverFx = domain.createEffect({
  async handler() {
    return await Driver.me()
  },
})

export const $driver = createStore<Driver | null>(null)
  .on(getDriverFx.doneData, (_, driver) => driver)
  .on([Gate.close, getDriverFx.failData], () => null)

sample({
  clock: Gate.open,
  target: getDriver,
})

sample({
  clock: getDriver,
  target: getDriverFx,
})

sample({
  clock: getDriverFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка получения данных пользователя',
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

export const $driverPending = createStore<boolean>(true)
  .on([getDriverFx.doneData, getDriverFx.failData], () => false)
  .on([Gate.close], () => true)
