import { Box, Typography } from '@mui/material'
import { useStore, useStoreMap } from 'effector-react'
import { balanceModel } from '~/entities/Balance'
import { CURRENCY } from '~/shared/config/constants'
import { ProgressBlock } from '~/shared/ui/Loaders'
import { myPalette } from '~/shared/ui/theme'
import { Wrapper } from './styled'

export function AmountAll() {
  const isAmountAllLoading = useStore(balanceModel.getAvailableFundsFx.pending)
  const { amount, amountWithCommission } =
    useStore(balanceModel.$availableFunds) || {}

  const selectedPayCard = useStoreMap(
    balanceModel.$selectedPayCard,
    (payCard) => payCard?.getAttributes(),
  )

  const renderSum = (sum?: number | null) => {
    return isAmountAllLoading ? (
      <ProgressBlock height={16} size={16} color='midnightBlue' />
    ) : (
      (sum || 0).toLocaleString()
    )
  }

  if (!selectedPayCard) {
    return (
      <Typography fontSize={12} color={myPalette.mutedText.main}>
        Лимит будет рассчитан после выбора карты
      </Typography>
    )
  }

  return (
    <>
      <Wrapper sx={{ height: '24px' }}>
        <Box fontSize={12} sx={{ marginRight: '6px' }}>
          С комиссией
        </Box>
        <Box>{renderSum(amount)}</Box>
        <Box sx={{ marginLeft: '6px' }}>{CURRENCY}</Box>
      </Wrapper>

      <Wrapper sx={{ height: '48px' }}>
        <Box fontSize={32} fontWeight={500} sx={{ marginRight: '8px' }}>
          {renderSum(amountWithCommission)}{' '}
        </Box>
        <Typography component='span' fontSize={24} fontWeight={500}>
          {CURRENCY}
        </Typography>
      </Wrapper>
    </>
  )
}
