import React, { ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'
import { Logo } from '~/shared/ui/Logo'
import { myPalette } from '~/shared/ui/theme'
import { MainBox, ContentWrapper, PageHeader } from './styled'

type Props = {
  children: ReactNode
  title: string
}

export function LogoContainer({ children, title }: Props) {
  return (
    <MainBox>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageHeader>
        <Logo
          sx={{ margin: '0 8px' }}
          logoWidth={350}
          color={myPalette.blue.paleBlue}
        />
      </PageHeader>
      <ContentWrapper>{children}</ContentWrapper>
    </MainBox>
  )
}
