import {
  IonToolbar as IonicIonToolbar,
  IonPage as IonicIonPage,
  IonContent as IonicIonContent,
  IonFooter as IonicIonFooter,
} from '@ionic/react'
import { Box, Typography, styled, Button as MuiButton } from '@mui/material'
import { HEADER_HEIGHT, myPalette } from '~/shared/ui/theme'
import { WhiteBlock } from '~/shared/ui/Wrappers'

export const Title = styled(Typography)`
  color: ${myPalette.dark.main};
  font-size: 16px;
`

export const IonPage = styled(IonicIonPage)`
  background: ${myPalette.aliceBlue.main};
`

export const IonToolbar = styled(IonicIonToolbar)`
  --background: ${myPalette.aliceBlue.main};
  --border-width: 0;
  --padding-end: 0;
  --padding-start: 0;
  --padding-bottom: 0;
  --padding-top: 0px;
  --box-shadow: none;
`

export const HeaderWrapper = styled(Box)`
  background: ${myPalette.aliceBlue.main};
  min-height: ${HEADER_HEIGHT};
  max-height: ${HEADER_HEIGHT};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const IonContent = styled(IonicIonContent)`
  --padding-top: 16px;
  --padding-bottom: 16px;
  --padding-start: 16px;
  --padding-end: 16px;
  --background: ${myPalette.aliceBlue.main};
`

export const CardBlock = styled(WhiteBlock)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Button = styled(MuiButton)`
  color: ${myPalette.blue.main};
  font-size: 16px;
  font-weight: 500;
  padding: 0;
`

export const ButtonWrapper = styled(Box)`
  position: absolute;
  left: 16px;
`

export const IonFooter = styled(IonicIonFooter)`
  padding: 16px 16px 24px;
`

export const LoadingWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${myPalette.aliceBlue.main};
`
