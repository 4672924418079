import { createDomain, createStore, sample } from 'effector'
import { createGate } from 'effector-react'
import { viewerModel } from '~/entities/viewer'
import { Auth, AxiosErrorType, User } from '~/shared/api'
import { replaceFx } from '~/shared/lib/history'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { sentryModel } from '~/shared/lib/sentry'
import { LoginStepsEnum } from './constants'
import { stepLoginPhoneModel } from './StepLoginPhone'

export const Gate = createGate()
export const domain = createDomain('features.auth.login')

export const goToStepLoginPhone = domain.createEvent()
export const goToStepLoginCode = domain.createEvent()

export const $loginStep = createStore<LoginStepsEnum>(
  LoginStepsEnum.LOGIN_PHONE,
)
  .on(goToStepLoginPhone, () => LoginStepsEnum.LOGIN_PHONE)
  .on(goToStepLoginCode, () => LoginStepsEnum.LOGIN_CODE)
  .on([Gate.open, Gate.close], () => LoginStepsEnum.LOGIN_PHONE)

export const requestLoginCodeFx = domain.createEffect<
  stepLoginPhoneModel.LoginPhoneFormValues['phone'],
  Promise<string>,
  AxiosErrorType
>({
  async handler(phone) {
    await Auth.code(phone)
    return phone
  },
})

sample({
  clock: requestLoginCodeFx.doneData,
  fn(phone) {
    return {
      message: `Код подтверждения входа отправлен на номер +${phone}`,
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: requestLoginCodeFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

export const loginFx = domain.createEffect<
  {
    phone: stepLoginPhoneModel.LoginPhoneFormValues['phone']
    code: string
  },
  { token: string; phone: string },
  AxiosErrorType
>({
  async handler({ phone, code }) {
    const result = await User.login({ phone, authCode: code })
    return { ...result, phone }
  },
})

sample({
  clock: loginFx.doneData,
  fn({ token }) {
    return token
  },
  target: viewerModel.tokenSaved,
})

sample({
  clock: loginFx.doneData,
  fn({ phone }) {
    return phone
  },
  target: [viewerModel.phoneSaved, sentryModel.setUser],
})

sample({
  clock: loginFx.failData,
  filter: (e) => e?.response?.status === 401,
  fn(e) {
    logger.error(e)
    return {
      message: 'Не верный код авторизации',
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: loginFx.failData,
  filter: (e) => e?.response?.status === 429,
  fn(e) {
    logger.error(e)
    return {
      message:
        'Превышен лимит попыток. Пожалуйста, попробуйте снова через 1 минут',
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: loginFx.failData,
  filter: (e) => e?.response?.status !== 429 && e?.response?.status !== 401,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка сервера',
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: loginFx.doneData,
  fn() {
    return '/'
  },
  target: replaceFx,
})
