/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Model,
  AxiosHttpClient,
  PaginationStrategy,
  Builder,
  PluralResponse,
  ToManyRelation,
  ToOneRelation,
} from 'coloquent'
import * as z from 'zod'

import { constants, env } from '~/shared/config'

import { Http } from './http'
import { LocalAuthStore } from './store'

export type { ToManyRelation, ToOneRelation }

export const authStore = new LocalAuthStore(
  constants.AUTH_TOKEN_STORAGE_KEY,
  constants.AUTH_PHONE_STORAGE_KEY,
)

export const http = new Http(env.API_URL, authStore)

export abstract class ApiModel<
  Schema extends z.ZodObject<any, any, any> | z.ZodIntersection<any, any>,
  Attributes extends Record<string, unknown> = z.infer<Schema>,
> extends Model {
  static jsonApiBaseUrl = env.API_URL
  protected static httpClient = new AxiosHttpClient(http.axiosInstance)

  static paginationStrategy = PaginationStrategy.PageBased

  constructor(attributes?: Partial<Attributes>, id?: UniqueId) {
    super()
    if (attributes) this.setAttributes(attributes)
    if (id) this.setApiId(id)
  }

  public getAttributes(): Attributes {
    return super.getAttributes() as Attributes
  }

  protected setAttributes(attributes: Partial<Attributes>) {
    Object.entries(attributes).forEach(([name, value]) => {
      this.setAttribute(name, value)
    })
  }

  static limit<M extends typeof Model & { new (): Model }>(
    this: M,
    limit: number,
  ): Builder<InstanceType<M>, PluralResponse<InstanceType<M>>> {
    const builder = new Builder<InstanceType<M>>(this).limit(limit)
    /*
     * Model.limit должен устанавливать limit, но почему-то этого не делает
     * Следующая строка исправляет поведение
     * */
    builder.getQuery().getPaginationSpec().setPageLimit(limit)
    return builder
  }
}
