import TelegramIcon from '@mui/icons-material/Telegram'
import { Box, Button, Typography } from '@mui/material'
import { useStoreMap } from 'effector-react'
import { supportModel } from '~/entities/Support'
import { Card, Title } from '~/entities/Support/ui/styled'
import { ContactMethodEnum } from '~/shared/config/enums'
import { sanitizePhoneNumber } from '~/shared/lib/utils'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { PhoneIcon } from '~/shared/ui/icons'

type DriverSupportServiceDrawerProps = {
  isShow: boolean
  onClose: () => void
}

export function DriverSupportServiceDrawer({
  isShow,
  onClose,
}: DriverSupportServiceDrawerProps) {
  const contacts =
    useStoreMap(supportModel.$contacts, (support) =>
      support?.map((support) => ({
        ...support.getAttributes(),
        id: support.getApiId() as UniqueId,
      })),
    ) || []

  return (
    <DrawerComponent isShow={isShow} onCancel={onClose}>
      {!contacts?.length && <>Контакты не доступны</>}
      {contacts.map((contact) => (
        <Card key={contact.id} sx={{ justifyContent: 'flex-start' }}>
          {contact.method === ContactMethodEnum.phone && <PhoneIcon />}
          {contact.method === ContactMethodEnum.telegram && <TelegramIcon />}
          <Box sx={{ ml: '12px' }}>
            {contact.method === ContactMethodEnum.phone && (
              <>
                <Typography fontSize={14} color='mutedText.main' mb='2px'>
                  {contact.description}
                </Typography>
                <Button
                  href={`tel:+${sanitizePhoneNumber(contact.value)}`}
                  sx={{ m: 0, p: 0 }}
                >
                  <Title>{contact.value}</Title>
                </Button>
              </>
            )}
            {contact.method === ContactMethodEnum.telegram && (
              <Button target='_blank' href={contact.value} sx={{ m: 0, p: 0 }}>
                <Typography fontSize={14} color='mutedText.main' mb='2px'>
                  {contact.description}
                </Typography>
              </Button>
            )}
          </Box>
        </Card>
      ))}
    </DrawerComponent>
  )
}
