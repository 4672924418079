import { Box, Button } from '@mui/material'
import { useGate, useStore } from 'effector-react'
import { Navigate, Outlet } from 'react-router-dom'
import { driverModel } from '~/entities/Driver'
import { viewerModel } from '~/entities/viewer'

import { ProgressBlock } from '~/shared/ui/Loaders'
import { NotFound } from '~/shared/ui/NotFound'

export function ProtectedDriverPage() {
  useGate(driverModel.Gate)
  const driverPending = useStore(driverModel.$driverPending)
  const authenticated = viewerModel.useAuthenticated()

  const driver = useStore(driverModel.$driver)
  const mainTaxiparkId = driver?.getMainTaxiparkId()

  if (driverPending || !authenticated) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ProgressBlock />
      </Box>
    )
  }

  if (!driverPending && !driver) {
    return (
      <NotFound
        message='Ошибка получения водителя, обратитесь в поддержку для решения проблемы'
        buttonNode={
          <Button onClick={() => viewerModel.signOut()}>Выйти</Button>
        }
      />
    )
  }

  return mainTaxiparkId ? <Outlet /> : <Navigate to='/taxiparks' />
}
