import { z } from 'zod'

import { CarBrand } from './carBrand'
import { ApiModel, ToOneRelation } from './core'

const schema = z.object({
  title: z.string(),
})

export type CarModelAttributes = z.infer<typeof schema>

export class CarModel extends ApiModel<typeof schema> {
  static jsonApiType = 'car-models'

  static schema = schema

  carBrand(): ToOneRelation<CarBrand, this> {
    return this.hasOne(CarBrand)
  }
  getCarBrand(): CarBrand {
    return this.getRelation('carBrand')
  }

  getTitle(): string {
    return this.getAttribute('title')
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getTitle(),
    }
  }

  static async fetchOptions(search?: string, carBrandId?: UniqueId) {
    let builder = CarModel.where('title', search ?? '')
    if (carBrandId) builder = builder.where('carBrandId', carBrandId)

    const response = await builder.get(1)
    return response.getData().map((o) => o.getOption())
  }
}
