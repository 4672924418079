import { LoadingButton } from '@mui/lab'
import { Stack, Typography } from '@mui/material'
import { Link as MuiLink } from '@mui/material'
import { useStore } from 'effector-react'
import React from 'react'
import { Link } from 'react-router-dom'
import { Login } from '~/features/auth/Login'
import { loginFx } from '~/features/auth/Login/model'
import { viewerModel } from '~/entities/viewer'
import { LogoContainer } from '~/shared/ui/LogoContainer'

function LoginPage() {
  const authenticated = viewerModel.useAuthenticated()
  const isLoginPending = useStore(loginFx.pending)
  const isLogoutPending = useStore(viewerModel.logoutFx.pending)

  return (
    <>
      {authenticated && !isLoginPending ? (
        <Stack
          spacing={2}
          sx={{
            textAlign: 'center',
            p: '60px 20px',
            background: '#fff',
            height: '100vh',
          }}
        >
          <Typography color='white'>Вы уже авторизованы</Typography>
          <MuiLink
            component={Link}
            to='/'
            style={{ marginTop: '35px', marginBottom: '20px' }}
          >
            На главную
          </MuiLink>
          <LoadingButton
            loading={isLogoutPending}
            variant='limeFlooded'
            size='large'
            onClick={() => viewerModel.signOut()}
          >
            Выйти
          </LoadingButton>
        </Stack>
      ) : (
        <LogoContainer title='Авторизация'>
          <Login />
        </LogoContainer>
      )}
    </>
  )
}

export default LoginPage
